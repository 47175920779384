
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-gray-900;
  @apply bg-gray-100;
  font-family: "Manrope", sans-serif;
}

*, *::before, *::after {
  box-sizing: border-box;
}

h2 {
  font-size: calc(1.3203125rem + 0.74375vw);
}

.form-group {
  @apply px-1.5
}

.form-label {
  @apply block text-sm font-medium text-gray-900 mb-2;
}

.form-input {
  @apply w-full h-9;
  @apply pl-4;
  @apply bg-white border border-[#e8e8e8] rounded-lg;
  @apply outline-none;
}

.form-submit {
  @apply text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full md:w-auto px-5 py-2.5 text-center
}
.text{
  @apply text-[#171717B2];
  @apply text-sm;
}
.button{
  @apply w-full h-10 rounded-xl text-sm text-center ;
  @apply bg-[#F3F3F3];
  @apply  whitespace-nowrap;
}
.new-button{
  @apply bg-[#F3F3F3] rounded whitespace-nowrap h-9 w-24 ml-8;
}
.disabled{
  @apply text-white font-normal rounded-xl min-w-fit p-1.5 max-h-10 px-4 w-full flex-auto;
  @apply bg-gray-200;
  @apply cursor-not-allowed;
  @apply  whitespace-nowrap;
}
.button-new{
  @apply  text-white bg-[#D41A6A] hover:bg-[#930046] rounded-xl  min-w-fit font-normal  p-1.5 max-h-10 px-4 w-full flex-auto ;
  @apply  whitespace-nowrap;

}
.input-new{
  @apply ps-4 p-1.5 border border-[#e8e8e8] rounded-md w-full;
}

.label-new-module{
  @apply block mb-2 text-sm font-medium text-gray-900
}

.input-new-module{
  @apply bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2
}

.validation-error{
  @apply text-red-500 text-[10px] mt-0.5;
}

.dateField {
  @apply p-1.5 border text-sm text-gray-700 border-gray-300 bg-gray-50 rounded-lg;
}

.textField {
  @apply text-gray-800 w-full p-1.5 border border-gray-300 bg-gray-50 rounded-lg;
}
.label {
  @apply absolute text-sm text-gray-600 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-50 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100
  peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2
  peer-focus:scale-75 peer-focus:-translate-y-4 left-1 whitespace-nowrap;
}

.mInput {
@apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5;
}
.main{
  @apply bg-white rounded-lg mx-6  p-4
}
.selectField{
  @apply  min-w-36 p-2 text-sm bg-gray-50 border border-gray-300 text-gray-600 rounded-lg;
}
.button-blue{
  @apply text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 whitespace-nowrap focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center disabled:opacity-50;
}
.tableSearch{
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5
}
.button-blue-tbl{
  @apply text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1 text-center inline-flex items-center;
}
.button-red{
  @apply px-3 py-2 text-sm text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium;
}
.table {
  @apply w-full text-sm text-left rtl:text-right text-gray-500 min-w-full;
}

.table-thead {
  @apply text-xs text-gray-700  bg-gray-50;
}

.table th,
.table td {
  @apply flex-1 px-6 py-4;
}

.validation-error{
  @apply text-red-500 text-[10px] mt-0.5;
}

.button-disabled{

  @apply opacity-50 cursor-not-allowed;
}